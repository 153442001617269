<template>
  <section id="rapor-selection">
    <b-container fluid>
      <b-row>
        <b-col xl="8" lg="6" sm="12" xs="12" cols="12">
          <b-card-actions
              ref="first-card"
              id="first-card"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-2">
              <b-col class="media-responsive">
                <b-media no-body>
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        variant="light-success"
                        class="pull-up"
                    >
                      <font-awesome-icon icon="fa-solid fa-file-invoice" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        kasaGet.satisFaturalari || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Satış Faturaları
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body>
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        variant="light-info"
                        class="pull-up"
                    >
                      <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        kasaGet.alisFaturalari || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Alış Faturaları
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-success"
                    >
                      <font-awesome-icon icon="fa-solid fa-plus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        kasaGet.tahsilatlar || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tahsilatlar
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-danger"
                    >
                      <font-awesome-icon icon="fa-solid fa-minus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        kasaGet.odemeler || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Ödemeler
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-success"
                    >
                      <font-awesome-icon icon="fa-solid fa-plus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        kasaGet.bekleyenFaturaTahsilatlari || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Bekleyen Tahsilat
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col class="media-responsive">
                <b-media no-body id="cari-media">
                  <b-media-aside
                      class="media-aside-p"
                  >
                    <b-avatar
                        size="48"
                        class="pull-up"
                        variant="light-danger"
                    >
                      <font-awesome-icon icon="fa-solid fa-minus" class="fa-2xl"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0 finans">
                      {{
                        kasaGet.bekleyenFaturaOdemeleri || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Bekleyen Ödemeler
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col xl="4" lg="6" sm="12" xs="12" cols="12">
          <b-card-actions
              ref="second-card"
              id="second-card"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Rapor Tarihi</h4>
                </b-col>
              </div>
            </b-row>
            <b-row class="mt-4">
              <b-col cols="12">
                <b-form-group
                    label="Başlangıç Tarihi: "
                    label-for="h-first-name"
                    label-cols-md="4"
                >
                  <flat-pickr
                      v-model="baslangic"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Bitiş Tarihi: "
                    label-for="h-first-name"
                    label-cols-md="4"
                >
                  <flat-pickr
                      v-model="bitis"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col xl="12" lg="12" sm="12" xs="12" cols="12" v-show="baslangic && bitis">
          <b-card-actions
              ref="third-card"
              @refresh="refreshStop('third-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Raporlar</h4>
                </b-col>
              </div>
            </b-row>
            <b-tabs class="mt-4">
              <b-tab title="Hesap Özeti">
                <b-row>
                  <b-col lg="6" md="6" sm="12" cols="12" clas="d-flex justify-content-start">
                    <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint per-page"
                      >
                        <b-form-select
                            v-model="firstPerPage"
                            :options="firstPageOptions"
                            size="sm"
                            @change="firstPerPageChange($event)"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" cols="12" class="d-flex justify-content-end">
                    <b-input-group size="sm" class="search-bar" :style="{width :width  < 1400 ? '60%!important':''}">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="firstFilter" type="search"/>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr/>
                <b-table
                    striped
                    small
                    hover
                    responsive
                    class="position-relative cari-group-table mt-1"
                    :items="hesapOzeti"
                    :fields="firstFields"
                    :per-page="firstPerPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="FirstOnFiltered"
                >
                  <template #cell(kasaID)="data">
                    <b-badge variant="primary">
                      {{ data.item.kasaID }}
                    </b-badge>
                  </template>
                  <template #cell(hareket)="data">
                    <b-badge :variant="data.item.hareket == 1 ? 'success':'danger'">
                      {{ data.item.hareket == 1 ? 'Tahsilat' : 'Ödeme' }}
                    </b-badge>
                  </template>
                  <template #cell(islemNotu)="data">
                    <p class="font-weight-bolder">{{ data.item.islemNotu }}</p>
                  </template>
                  <template #cell(kayitTarih)="data">
                    <p>{{ data.item.kayitTarih.slice(0, 16) }}</p>
                  </template>
                  <template #cell(tutar)="data">
                    <p class="font-weight-bolder">
                      {{
                        data.item.tutar || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </p>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ firstTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="firstCurrentPage"
                        @change="firstPageChange($event)"
                        :total-rows="firstTotalRows"
                        :per-page="firstPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Alış Faturaları" @click="alisFaturalariRapor">
                <b-row>
                  <b-col lg="6" md="6" sm="12" cols="12" clas="d-flex justify-content-start">
                    <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint per-page"
                      >
                        <b-form-select
                            v-model="secondPerPage"
                            :options="secondPageOptions"
                            size="sm"
                            @change="secondPerPageChange($event)"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" cols="12" class="d-flex justify-content-end">
                    <b-input-group size="sm" class="search-bar" :style="{width :width  < 1400 ? '60%!important':''}">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="secondFilter" type="search"/>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr/>
                <b-table
                    striped
                    small
                    hover
                    responsive
                    class="position-relative cari-group-table mt-1"
                    :items="alisFaturalari"
                    :fields="secondFields"
                    :per-page="secondPerPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="FirstOnFiltered"
                    @row-clicked="goFatura"
                >
                  <template #cell(faturaUnvan)="data">
                    <strong class="text-dark fatura-notu" v-if="data.item.faturaNotu"
                            v-b-tooltip.hover.top="data.item.faturaNotu"
                    >{{
                        data.item.faturaNotu.slice(0, 50)
                      }}{{ data.item.faturaNotu.length > 40 ? '...' : '' }} </strong>
                    <p class="mb-0" v-if="data.item.faturaUnvan">
                      {{ data.item.faturaUnvan.slice(0, 70) }}
                      {{ data.item.faturaUnvan > 60 ? '...' : '' }}
                    </p>
                    <p class="mb-0" v-else>
                      {{
                        (data.item.adi, data.item.soyAdi).slice(0, 70)
                      }}{{ data.item.adi.length + data.item.soyAdi.length > 60 ? '...' : '' }}
                    </p>
                  </template>
                  <template #cell(faturaNo)="data">
                    <b-row>
                      <b-col cols="12">
                        <p class="text-success fatura-no">{{ data.item.faturaNo }}</p>
                        <b-badge v-if="data.item.faturaNo" variant="bgSuccess" style="font-weight: bold">
                          ETTN: {{ data.item.faturaID }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </template>
                  <template #cell(kayitTarih)="data">
                    <b-badge
                        id="bakiye-badge"
                        variant="light-primary"
                        size="sm"
                        class="float-right"
                    >
                      {{ data.item.kayitTarih.slice(0, 16) }}
                    </b-badge>
                  </template>
                  <template #cell(toplamTutar)="data">
                    <p class="d-block text-right font-weight-bolder">
                      {{
                        data.item.toplamTutar || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </p>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ secondTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="secondCurrentPage"
                        @change="secondPageChange($event)"
                        :total-rows="secondTotalRows"
                        :per-page="secondPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Satış Faturaları" @click="satisFaturalariRapor">
                <b-row>
                  <b-col lg="6" md="6" sm="12" cols="12" clas="d-flex justify-content-start">
                    <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint per-page"
                      >
                        <b-form-select
                            v-model="thirdPerPage"
                            :options="thirdPageOptions"
                            size="sm"
                            @change="thirdPerPageChange($event)"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" cols="12" class="d-flex justify-content-end">
                    <b-input-group size="sm" class="search-bar" :style="{width :width  < 1400 ? '60%!important':''}">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="thirdFilter" type="search"/>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr/>
                <b-table
                    striped
                    small
                    hover
                    responsive
                    class="position-relative cari-group-table mt-1"
                    :items="satisFaturalari"
                    :fields="thirdFields"
                    :per-page="thirdPerPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="FirstOnFiltered"
                    @row-clicked="goFatura"
                >
                  <template #cell(faturaUnvan)="data">
                    <strong class="text-dark fatura-notu" v-if="data.item.faturaNotu"
                            v-b-tooltip.hover.top="data.item.faturaNotu"
                    >{{
                        data.item.faturaNotu.slice(0, 50)
                      }}{{ data.item.faturaNotu.length > 40 ? '...' : '' }} </strong>
                    <p class="mb-0" v-if="data.item.faturaUnvan">
                      {{ data.item.faturaUnvan.slice(0, 70) }}
                      {{ data.item.faturaUnvan > 60 ? '...' : '' }}
                    </p>
                    <p class="mb-0" v-else>
                      {{
                        (data.item.adi, data.item.soyAdi).slice(0, 70)
                      }}{{ data.item.adi.length + data.item.soyAdi.length > 60 ? '...' : '' }}
                    </p>
                  </template>
                  <template #cell(faturaNo)="data">
                    <b-row>
                      <b-col cols="12">
                        <p class="text-success fatura-no">{{ data.item.faturaNo }}</p>
                        <b-badge v-if="data.item.faturaNo" variant="bgSuccess" style="font-weight: bold">
                          ETTN: {{ data.item.faturaID }}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </template>
                  <template #cell(kayitTarih)="data">
                    <b-badge
                        id="bakiye-badge"
                        variant="light-primary"
                        size="sm"
                        class="float-right"
                    >
                      {{ data.item.kayitTarih.slice(0, 16) }}
                    </b-badge>
                  </template>
                  <template #cell(toplamTutar)="data">
                    <p class="d-block text-right font-weight-bolder">
                      {{
                        data.item.toplamTutar || 0
                      }}
                      <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                    </p>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ thirdTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="thirdCurrentPage"
                        @change="thirdPageChange($event)"
                        :total-rows="thirdTotalRows"
                        :per-page="thirdPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Gider Kayıtları" @click="giderlerRapor">
                <b-row>
                  <b-col lg="6" md="6" sm="12" cols="12" clas="d-flex justify-content-start">
                    <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                          style="width: 35%"
                          :style="{width : width< 1150 ? '80%': width < 1600 ? '50%':''}"
                      >
                        <b-form-select
                            v-model="fourthPerPage"
                            :options="fourthPageOptions"
                            @change="fourthPerPageChange($event)"
                            size="sm"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12" cols="12" class="d-flex justify-content-end">
                    <b-input-group size="sm" class="search-bar" :style="{width :width  < 1400 ? '60%!important':''}">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="fourthFilter" type="search"/>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative kasa-table mt-1"
                    :per-page="fourthPerPage"
                    :items="giderler"
                    :fields="fourthFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter-included-fields="filterOn"
                    @filtered="FirstOnFiltered"
                >
                  <template #cell(hareket)="data">
                    <b-badge :variant="data.item.hareket == 1 ? 'success':'danger'" id="firma-unvan-badge"
                             class="align-top"
                    >
                      {{ data.item.hareket == 1 ? 'Tahsilat' : ' Ödeme ' }}
                    </b-badge>
                    <p id="firma-unvan-yetkili">{{ data.item.adi }}</p>
                  </template>
                  <template #cell(giderGrupID)="data">
                    <feather-icon icon="UsersIcon"/>
                    <strong class="ml-50">
                      {{ $store.getters.GIDER_GRUP_NAME_GET(data.item.giderGrupID) }}
                    </strong>
                  </template>
                  <template #cell(giderAdi)="data">
                    <strong>
                      {{ data.item.giderAdi }}
                    </strong>
                  </template>
                  <template #cell(kayitTarih)="data">
                    {{ data.item.kayitTarih.slice(0, 16) }}
                  </template>
                  <template #cell(tutar)="data">
                    <strong class="ml-50">
                      {{
                        data.item.tutar || 0
                      }} TL
                    </strong>
                  </template>
                  <template #cell(kasaBakiye)="data">
                    <strong class="ml-50">
                      {{
                        data.item.kasaBakiye || 0
                      }} TL
                    </strong>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ fourthTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="fourthCurrentPage"
                        @change="fourthPageChange($event)"
                        :total-rows="fourthTotalRows"
                        :per-page="fourthPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BTab,
  BTabs,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormTextarea,
  BCard,
  BCardText,
  BContainer,
  BFormSelect,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem, BInputGroupPrepend, BPagination,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BPagination,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormTextarea,
    flatPickr,
    BCard,
    BCardText,
    BContainer,
    BFormSelect,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      fourthFilter: null,
      fourthPerPage: 20,
      fourthPageOptions: [5, 10, 20, 50, 100, 200, 500],
      fourthToplamKayit: 1,
      fourthTotalRows: 1,
      fourthCurrentPage: 1,
      thirdFilter: null,
      thirdPerPage: 20,
      thirdPageOptions: [5, 10, 20, 50, 100, 200, 500],
      thirdToplamKayit: 1,
      thirdTotalRows: 1,
      thirdCurrentPage: 1,
      secondFilter: null,
      secondPerPage: 20,
      secondPageOptions: [5, 10, 20, 50, 100, 200, 500],
      secondToplamKayit: 1,
      secondTotalRows: 1,
      secondCurrentPage: 1,
      firstFilter: null,
      firstPerPage: 20,
      firstPageOptions: [5, 10, 20, 50, 100, 200, 500],
      firstToplamKayit: 1,
      firstTotalRows: 1,
      firstCurrentPage: 1,
      firstFields: [
        {
          key: 'kasaID',
          label: 'Kasa ID',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'hareket',
          label: 'Hareket',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'islemNotu',
          label: 'İşlem Notu',
          sortable: true,
          thStyle: {
            width: '50%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '50%' },
        },
        {
          key: 'kayitTarih',
          label: 'İşlem Tarihi',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tutar',
          label: 'İşlem Tutarı',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
          tdClass: ['text-right'],
          thClass: ['text-right'],
        },
      ],
      secondFields: [
        {
          key: 'faturaUnvan',
          label: 'Fatura Bilgisi',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'faturaNo',
          label: 'Fatura No',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'kayitTarih',
          label: 'Düzenlenme Tarihi',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'toplamTutar',
          label: 'Fatura Tutarı',
          sortable: true,
          thStyle: {
            width: '10%',
            textAlign: 'end',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdClass: ['d-flex', 'justify-content-end'],
          tdStyle: { width: '10%' },
        },
      ],
      thirdFields: [
        {
          key: 'faturaUnvan',
          label: 'Fatura Bilgisi',
          sortable: true,
          thStyle: {
            width: '20%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'faturaNo',
          label: 'Fatura No',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'kayitTarih',
          label: 'Düzenlenme Tarihi',
          sortable: true,
          thStyle: {
            width: '5%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '5%' },
        },
        {
          key: 'toplamTutar',
          label: 'Fatura Tutarı',
          sortable: true,
          thStyle: {
            width: '10%',
            textAlign: 'end',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdClass: ['d-flex', 'justify-content-end'],
          tdStyle: { width: '10%' },
        },
      ],
      fourthFields: [
        {
          key: 'hareket',
          label: 'İşlem Türü',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'giderGrupID',
          label: 'Gider Grubu',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'giderAdi',
          label: 'İşlem Notu',
          sortable: true,
          thStyle: {
            width: '50%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '30%' },
        },
        {
          key: 'kayitTarih',
          label: 'İşlem Tarihi',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tutar',
          label: 'İşlem Tutarı',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
          tdClass: ['text-right'],
          thClass: ['text-right'],
        },
      ],
      baslangic: null,
      bitis: null,
    }
  },
  computed: {
    giderler() {
      return Object.values(store.getters.R_GIDERLER)
    },
    satisFaturalari() {
      return Object.values(store.getters.R_SATIS_FATURALARI)
    },
    alisFaturalari() {
      return Object.values(store.getters.R_ALIS_FATURALARI)
    },
    hesapOzeti() {
      return Object.values(store.getters.R_HESAP_OZETI_GET)
    },
    kasaGet() {
      return store.getters.R_KASA_GET
    },
  },
  methods: {
    fourthPageChange(newVal) {
      const veri = newVal * this.fourthPerPage - this.fourthPerPage
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('giderlerRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: this.fourthPerPage,
            baslangic: veri,
            searchKey: this.fourthFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.fourthTotalRows = res.toplamKayit
            this.fourthToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    fourthPerPageChange(newVal) {
      this.cardLoadingManuelOn('third-card')
      const veri = this.fourthCurrentPage * newVal - newVal
      this.$store
          .dispatch('giderlerRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.fourthFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.fourthTotalRows = res.toplamKayit
            this.fourthToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    giderlerRapor() {
      store.dispatch('giderGrupListesi')
      this.cardLoadingManuelOn('third-card')
      store.dispatch('giderlerRapor', {
        start: this.baslangic,
        end: this.bitis,
      })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.fourthTotalRows = res.toplamKayit
            this.fourthToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    thirdPageChange(newVal) {
      const veri = newVal * this.thirdPerPage - this.thirdPerPage
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('satisFaturalariRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: this.thirdPerPage,
            baslangic: veri,
            searchKey: this.thirdFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    thirdPerPageChange(newVal) {
      this.cardLoadingManuelOn('third-card')
      const veri = this.thirdCurrentPage * newVal - newVal
      this.$store
          .dispatch('satisFaturalariRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.thirdFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    satisFaturalariRapor() {
      this.cardLoadingManuelOn('third-card')
      store.dispatch('satisFaturalariRapor', {
        start: this.baslangic,
        end: this.bitis,
      })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    secondPageChange(newVal) {
      const veri = newVal * this.secondPerPage - this.secondPerPage
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('alisFaturalariRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: this.secondPerPage,
            baslangic: veri,
            searchKey: this.secondFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    secondPerPageChange(newVal) {
      this.cardLoadingManuelOn('third-card')
      const veri = this.secondCurrentPage * newVal - newVal
      this.$store
          .dispatch('alisFaturalariRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.secondFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    alisFaturalariRapor() {
      this.cardLoadingManuelOn('third-card')
      store.dispatch('alisFaturalariRapor', {
        start: this.baslangic,
        end: this.bitis,
      })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    firstPageChange(newVal) {
      const veri = newVal * this.firstPerPage - this.firstPerPage
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('hesapOzetiRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: this.firstPerPage,
            baslangic: veri,
            searchKey: this.firstFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    firstPerPageChange(newVal) {
      this.cardLoadingManuelOn('third-card')
      const veri = this.firstCurrentPage * newVal - newVal
      this.$store
          .dispatch('hesapOzetiRapor', {
            start: this.baslangic,
            end: this.bitis,
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.firstFilter,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    goFatura(item) {
      router.push({
        name: 'faturaDetay',
        params: { id: item.kayitID },
      })
    },
    FirstOnFiltered(filteredItems) {
      this.firstTotalRows = filteredItems.length
      this.firstCurrentPage = 1
    },
    print() {
      window.print()
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    cardLoadingOn() {
      this.$refs['first-card'].showLoading = true
      this.$refs['second-card'].showLoading = true
      this.$refs['third-card'].showLoading = true
    },
    cardLoadingOf() {
      this.$refs['first-card'].showLoading = false
      this.$refs['second-card'].showLoading = false
      this.$refs['third-card'].showLoading = false
    },
    cardLoadingManuelOn(ref) {
      this.$refs[ref].showLoading = true
    },
    cardLoadingManuelOf(ref) {
      this.$refs[ref].showLoading = false
    },
  },
  watch: {
    firstFilter(newVal) {
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('hesapOzetiRapor', {
            uzunluk: this.firstPerPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    secondFilter(newVal) {
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('alisFaturalariRapor', {
            uzunluk: this.secondPerPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    thirdFilter(newVal) {
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('satisFaturalariRapor', {
            uzunluk: this.thirdPerPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    fourthFilter(newVal) {
      this.cardLoadingManuelOn('third-card')
      this.$store
          .dispatch('giderlerRapor', {
            uzunluk: this.thirdPerPage,
            baslangic: 0,
            searchKey: newVal,
            start: this.baslangic,
            end: this.bitis,
          })
          .then(res => {
            this.cardLoadingManuelOf('third-card')
            this.fourthTotalRows = res.toplamKayit
            this.fourthToplamKayit = res.liste?.length
          })
          .catch(() => this.cardLoadingManuelOf('third-card'))
    },
    baslangic(val) {
      if (this.bitis) {
        this.cardLoadingOn()
        store.dispatch('kasaRaporu', {
          start: val,
          end: this.bitis,
        })
            .then(() => this.cardLoadingOf())
            .catch(() => this.cardLoadingOf())
        store.dispatch('hesapOzetiRapor', {
          start: this.baslangic,
          end: val,
        })
            .then(res => {
              this.cardLoadingOf()
              this.firstTotalRows = res.toplamKayit
              this.firstToplamKayit = res.liste?.length
            })
            .catch(() => this.cardLoadingOf())
        store.dispatch('alisFaturalariRapor', {
          start: this.baslangic,
          end: val,
        })
            .then(res => {
              this.cardLoadingOf()
              this.secondTotalRows = res.toplamKayit
              this.secondToplamKayit = res.liste?.length
            })
            .catch(() => this.cardLoadingOf())
      }
    },
    bitis(val) {
      if (this.baslangic && val) {
        this.cardLoadingOn()
        store.dispatch('kasaRaporu', {
          start: this.baslangic,
          end: val,
        })
            .then(() => this.cardLoadingOf())
            .catch(() => this.cardLoadingOf())
        store.dispatch('hesapOzetiRapor', {
          start: this.baslangic,
          end: val,
        })
            .then(res => {
              this.cardLoadingOf()
              this.firstTotalRows = res.toplamKayit
              this.firstToplamKayit = res.liste?.length
            })
            .catch(() => this.cardLoadingOf())
        store.dispatch('alisFaturalariRapor', {
          start: this.baslangic,
          end: val,
        })
            .then(res => {
              this.cardLoadingOf()
              this.secondTotalRows = res.toplamKayit
              this.secondToplamKayit = res.liste?.length
            })
            .catch(() => this.cardLoadingOf())
      }
    },
  },
  beforeDestroy() {
    store.commit('BANKA_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
#rapor-selection .card-header {
  display: none !important;
}

.card-right-button {
  transform: translateY(10%) !important;
}

@media screen and (max-width: 3000px) {
  .per-page {
    width: 30% !important;
  }
}

@media screen and (max-width: 2000px) {
  .per-page {
    width: 35% !important;
  }
}

@media screen and (max-width: 1800px) {
  .per-page {
    width: 40% !important;
  }
}

@media screen and (max-width: 1600px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1100px) {
  .per-page {
    width: 55% !important;
  }
}

@media screen and (max-width: 1000px) {
  .per-page {
    width: 65% !important;
  }
}

@media screen and (max-width: 900px) {
  .per-page {
    width: 75% !important;
  }
}

@media screen and (max-width: 800px) {
  .per-page {
    width: 90% !important;
  }
}

.search-bar {
  width: 30% !important;
}

.search-input {
  border-color: #c3baba !important;
}

p, td, th {
  margin: 0 !important;
}
</style>
